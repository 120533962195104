$primaryColor: #5F2EFB !default;
$primaryDarkColor: #884FFF !default;
$primaryDarkerColor: #884FFF !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

$secondaryColor: #F96A51 !default;
$secondaryLightColor: #FFC3A3 !default;
$secondaryDarkColor: #AA2317 !default;
$secondaryDarkerColor: #840000 !default;
$secondaryTextColor: #ffffff !default;

@import '../_variables';
@import './_fonts';
@import 'assets/theme-base/_components';
@import '../_extensions';
